import React, { useEffect } from  "react"
import logoWhite from './logo3_white.png';
import logoAqua from './logo3_aqua.png';
import './App.css';
import {AiFillGithub} from "react-icons/ai"
import {SiProtonmail} from "react-icons/si";
import {SiGnuprivacyguard} from "react-icons/si";


import {useState, useRef} from "react";
import {BlinkingCursorTextBuilder} from "react-animated-text-builders";
import {TerminalUi} from "react-simple-terminal-ui"
import "react-simple-terminal-ui/dist/styles.css"

const techstyle = {height : "250px", display : "flex", justifyContent :"center", width : "100%", marginTop : "10px"}
const initialFeed = ["Welcome to movcmpret.com!",<div>Type <b>help</b> for a list of available commands.</div>]
const commands = [{
    command: "help", print: <div>
        <b>whoami</b> print effective username<br />
        <b>ls</b> list competences<br />
        <b>ls -la</b> list competences with details<br />
        <b>rm -rf</b> free cookies<br />
        <br/>
    </div>
  },
  { command: "whoami", print: "visitor"},
  { command: "ls", print: "Software Architecture & Development, Cloud Development, IT Security, IoT"},
  { command: "ls -la", print:<div>
    <b>Languages:</b> js/ts, php, c#, java, python, rust, c++, react, redux<br />
    <b>Technologies:</b> laravel, react, redux, docker, ci/cd pipelines, debian/arch linux, arduino, stm32 <br />
    <b>Best Practices: </b> design patterns, agile development, KISS, zero trust, TDD<br />
    <b>Certificates: </b> oscp, opst<br />
    <br/>
    </div>
},
  { command: "h4x0r", callback: () => alert("You are a trv3 h4ck3r!")},
  { command: "rm -rf", callback: () => window.location.href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" }]

  
let isCursorToggled = false
let cursorTitleLoopIter = 0
let delay = 100
function toggleCursorTitle ()
{
    let ele = document.getElementById("title")
    if( cursorTitleLoopIter < "movcmpret".length)
    {
        ele.text = ele.text.substring(0, ele.text.length-1) + "movcmpret"[cursorTitleLoopIter] + "▮"
        cursorTitleLoopIter++
    }
    else if(isCursorToggled)
    {
        ele.text = "movcmpret"
        delay = 400
    }
    else
        ele.text = "movcmpret▮"
    isCursorToggled = !isCursorToggled

    setTimeout(()=>
    {
        toggleCursorTitle()
    }, delay)
}
toggleCursorTitle();

function App() {

    let [ghActive, setGhActive] = useState(false)
    let [ghClasses, setGhClasses] = useState("icon icon-fade-in")
    let [ghWrapperClasses, setGhWrapperClasses] = useState("icon-wrapper-pointer")


    let [pmActive, setPmActive] = useState(false)
    let [pmClasses, setPmClasses] = useState("icon icon-fade-in")
    let [pmWrapperClasses, setPmWrapperClasses] = useState("icon-wrapper-pointer")

    let [gpgActive, setGpgActive] = useState(false)
    let [gpgClasses, setGpgClasses] = useState("icon icon-fade-in")

    let [techActive, setTechActive] = useState(false)


    setTimeout(() => {
        setGhActive(true)
        setTimeout(() => {
            setGhClasses("icon");
                    setTimeout(() => {
                        setPmActive(true)
                        setTimeout(() => {
                                setPmClasses("icon");
                                setTimeout(() => {
                                    setGpgActive(true);
                                    setTimeout(() => {
                                            setGpgClasses("icon")
                                        setTimeout(() => {
                                                setTechActive(true)
                                                },1500)
                                        },
                                        1000)
                                }, 0);
                            },
                            1000)
                    }, 0)
           
        }, 500)
    }, 0)



    return (

        <div style={{
            display: "flex",
            flex: 1,
            lexDirection: "column",
            borderTopLeftRadius: "10%",
            borderBottomRightRadius: "10%",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            marginTop: "25px",
            marginBottom: "25px",
            justifyContent: "center",
            marginRight: "5vw",
            marginLeft: "5vw",
            backgroundColor: "#282c34",
            minHeight: "650px"
        }}>

            <div style={{
                display: "flex",
                flex: 1,
                maxWidth: "750px",
                justifyContent: "center",
                flexDirection: "row",
            }}>
                {/********************+*/}

                <div style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    flexDirection: "column",
                }}>
                    {/*Header Logo*/}
                    <div className="logo">
                        <img src={logoWhite} style={window.innerWidth > 500?{ maxHeight: "100%", maxWidth: "100%", position: "absolute"} : {width :  (window.innerWidth-50), position: "absolute"}}
                             alt="logo"/>
                        <img className={"logo-white"} src={logoAqua}
                             style={window.innerWidth > 500?{ maxHeight: "100%", maxWidth: "100%", position: "absolute"} : {width : (window.innerWidth-50), position: "absolute"}} alt="logo"/>
                    </div>
                    {/*Body*/}
                    <div style={{display : "flex", justifyContent :"center", height : "50px", marginLeft :"10px", marginRight :"10px"}}>
                    <BlinkingCursorTextBuilder
                                timeout={50}
                                blinkTimeAfterFinish={0}
                                >
                                    software engineer, penetration tester & computer enthusiast
                                </BlinkingCursorTextBuilder>
                    </div>
                    
                    <div style={{
                        display: "flex",
                        marginTop: "0px",
                        flex: 1,
                        flexDirection: "column",
                    }}>
                        {ghActive ?
                            <div className={ghWrapperClasses} style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: " 15px",
                            marginLeft : "15px"
                        }}
                            onClick={()=>
                            {
                                setGhWrapperClasses("icon-wrapper-shake")
                                setTimeout(()=>
                                {
                                    window.open("https://www.github.com/movcmpret");
                                    setGhWrapperClasses("icon-wrapper-pointer")
                                },750)
                            }}
                            >
                            <div style={{marginRight: "15px"}}>
                                <AiFillGithub className={ghClasses}/>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                <BlinkingCursorTextBuilder
                                timeout={50}
                                blinkTimeAfterFinish={0}
                                >
                                    movcmpret
                                </BlinkingCursorTextBuilder>
                            </div>
                        </div> : null}

                    
                        {pmActive ? <div style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: " 15px",
                            marginLeft : "15px"
                        }}
                        className= {pmWrapperClasses}
                         onClick={()=>
                                         {
                         setPmWrapperClasses("icon-wrapper-shake")
                          setTimeout(()=>
                              {
                                  window.location.href =("mailto:movcmpret@protonmail.com");
                                  setPmWrapperClasses("icon-wrapper-pointer")
                               },750)
                        }}
                        >
                            <div style={{marginRight: "15px"}}>
                                <SiProtonmail className={pmClasses}/>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                <BlinkingCursorTextBuilder
                                    timeout={50}
                                    blinkTimeAfterFinish={0}
                                >
                                    movcmpret [at] protonmail.com
                                </BlinkingCursorTextBuilder>
                            </div>
                        </div> : null}

                        {gpgActive ? <div style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: " 15px",
                            marginLeft : "15px"
                        }}>
                            <div style={{marginRight: "15px"}}>
                                <SiGnuprivacyguard className={gpgClasses}/>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                                <BlinkingCursorTextBuilder
                                timeout={50}
                                blinkTimeAfterFinish={-1}
                            >
                            gpg --recv-key D391A5DE30D29CC6127BD370EF6D38FD5725F9EB
                            </BlinkingCursorTextBuilder>
                            </div>
                        </div> : null}

                        <div style={techActive? {opacity : 1, transition : "opacity 1s", ...techstyle} : { opacity : 0, ...techstyle}}>
                        <TerminalUi
                            style={{width :"100%"/*, backgroundColor :"#282c34"*/}}
                            title={"visitor@my-desktop:~"}
                            initialFeed={initialFeed}
                            prompt='[visitor@my-desktop]$ '
                            commands={commands}
                            commandNotFoundMessage={cmd => `bash: ${cmd}: command not found`}
                         />
                        </div>
              
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
